<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>

	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Label / Value Pair'" [includeDescription]="true"
			[otherNames]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example<kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change Example">
					<option value="emphasized-labels">Emphasized labels</option>
					<option value="emphasized-values">Emphasized values</option>
					<option value="value-with-icon">Value with icon</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<dl class="sh-label-value-pair sh-pair-em-label"
					[class.page-live-show]="currLive === 'emphasized-labels'">
					<dt class="sh-pair-label">Amend Token Resource ID</dt>
					<dd class="sh-pair-value">https://api.ic3.avecto.com</dd>
				</dl>
				<dl class="sh-label-value-pair sh-pair-em-value"
					[class.page-live-show]="currLive === 'emphasized-values'">
					<dt class="sh-pair-label">User Type</dt>
					<dd class="sh-pair-value">Advanced User</dd>
				</dl>
				<dl class="sh-label-value-pair sh-pair-em-label"
					[class.page-live-show]="currLive === 'value-with-icon'">
					<dt class="sh-pair-label">User ID</dt>
					<dd class="sh-pair-value">
						<kendo-svg-icon [icon]="windows" class="sh-icon--windows"></kendo-svg-icon>
						Frank Fields
					</dd>
				</dl>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Add the <code>shield</code> class and either the
					<code>dt-em</code> or <code>dd-em</code> class to any
					applicable <code>&lt;dl&gt;</code> elements.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark" [hidden]="this.currLive !== 'emphasized-labels'">
				{{ emLabelsHTML }}
			</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'emphasized-values'">
				{{ emValuesHTML }}
			</pre>
			<pre prism class="dark" [hidden]="this.currLive !== 'value-with-icon'">
				{{ withIconHTML }}
			</pre>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<span class="page-style-list-title">All variations</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Emphasized label</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyLabel" [data]="item"></app-style-row>
					</ul>
					<span class="page-style-list-title">Emphasized value</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypographyValue" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>

	<section class="page-section" id="variations">
		<h2 id="sectionTitleVariations" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('variations')">
			Variations <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="variations-emphasized-label">
			<h3 id="subSectionTitleStyles" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-emphasized-label')">
				Emphasized label <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Label/Value pairs with a semibold label. These give more emphasis on the label instead of the value.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="variations-emphasized-value">
			<h3 id="subSectionTitleStyles" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('variations-emphasized-value')">
				Emphasized value <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Label/Value pairs with a semibold value. These give more emphasis on the value instead of the label.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-do-list-title">Do:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use label/value pairs to display groups of read-only data.
						</li>
						<li class="page-section-list-item">
							Use label/value pairs to show a read-only version of an editable form.
						</li>
						<li class="page-section-list-item">
							Try to keep all label/value pairs in a similar section, with either
							all labels or values emphasized.
						</li>
					</ul>
				</li>
				<li class="page-section-list-item" style="margin-top: 5px">
					<span class="page-section-do-list-title">Don’t:</span>
					<ul class="page-section-do-list">
						<li class="page-section-list-item">
							Use null values. Instead, use a double-dash <strong>--</strong>.
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="sectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">
						Value with icon
					</span>
					An icon can be paired with a value. These icons appear to the left
					of the value with a margin of 8px between them. These icons are not
					interactable or change state.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">
						Length
					</span>
					If necessary, the label/value pair can wrap to additional lines.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">
						When to use emphasized labels:
					</span>
					If the value isn't easily recognizable, like in a URL or
					values with mixed characters.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/label_value/label_value_prominent_labels.png">
					</div>
					<span class="redlines-caption">Example of emphasized label.</span>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">
						When to use emphasized values:
					</span>
					If the value is recognizable.
				</li>
				<li class="page-section-list-item">
					<div class="redlines-container">
						<img src="/assets/images/redlines/label_value/label_value_promient_values.png">
					</div>
					<span class="redlines-caption">Example of emphasized value.</span>
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
			<!-- <li class="page-related-list-item">
				<a href="patterns/form">Forms pattern</a>
			</li> -->
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Label / Value Pair Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
