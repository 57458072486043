import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import * as ShieldIcons from '@beyondtrust/shield-icons';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import {
	iconStylesDarkTheme,
	iconStylesDarkThemeStatus,
	iconStylesDefaultTheme,
	iconStylesDefaultThemeStatus,
	iconStylesStructure,
	iconStylesTypography,
} from './icon-styles';
import { ChangelogService } from 'src/app/services/changelog.service';

const warningDuotoneIcon = {
	name: 'shCircleWarningDuotone',
	content:
		'<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0423 3.76202C7.15373 3.76202 3.19037 7.72538 3.19037 12.614C3.19037 17.5026 7.15373 21.466 12.0423 21.466C16.931 21.466 20.8943 17.503 20.8943 12.614C20.8943 7.72496 16.931 3.76202 12.0423 3.76202ZM12.0423 18.1466C11.4304 18.1466 10.9357 17.6511 10.9357 17.04C10.9357 16.4289 11.4304 15.9334 12.0423 15.9334C12.6543 15.9334 13.1489 16.4285 13.1489 17.04C13.1489 17.6515 12.6543 18.1466 12.0423 18.1466ZM13.1489 12.614C13.1489 13.2255 12.6543 13.7206 12.0423 13.7206C11.4304 13.7206 10.9357 13.2251 10.9357 12.614V8.18801C10.9357 7.57736 11.4304 7.08141 12.0423 7.08141C12.6543 7.08141 13.1489 7.57736 13.1489 8.18801V12.614Z"/><g clip-path="url(#clip0_10636_275)"><path d="M22.3951 14.8185C23.4618 9.11843 19.7058 3.63288 14.0058 2.56615C8.30573 1.49942 2.82018 5.25545 1.75345 10.9555C0.686718 16.6555 4.44275 22.1411 10.1428 23.2078C15.8428 24.2745 21.3284 20.5185 22.3951 14.8185Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0779 19.4492C11.3541 19.4492 10.7609 18.856 10.7609 18.1323C10.7609 17.4085 11.3423 16.8153 12.0779 16.8153C12.8135 16.8153 13.3948 17.4085 13.3948 18.1323C13.3948 18.856 12.8135 19.4492 12.0779 19.4492Z" fill="#253746"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3948 12.8882C13.3948 13.6119 12.8135 14.2051 12.0779 14.2051C11.3423 14.2051 10.7609 13.6119 10.7609 12.8882V7.63226C10.7609 6.90853 11.3423 6.31531 12.0779 6.31531C12.8135 6.31531 13.3948 6.90853 13.3948 7.63226V12.8882Z" fill="#253746"/></g><defs><clipPath id="clip0_10636_275"><rect width="21" height="21" fill="white" transform="translate(1.57788 2.38818)"/></clipPath></defs>',
	viewBox: '0 0 25 25',
};

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.less'],
})
export class IconComponent implements OnDestroy {
	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public plusIcon = ShieldIcons.shPlus;
	public binoculars = ShieldIcons.shBinoculars;
	public bridge = ShieldIcons.shBridge;
	public report = ShieldIcons.shReport;

	public success = ShieldIcons.shCheck;
	public warning = warningDuotoneIcon;
	public error = ShieldIcons.shCircleSlash;
	public info = ShieldIcons.shCircleInfo;

	public btInsights = ShieldIcons.shBtInsights;
	public btPRA = ShieldIcons.shBtPra;
	public btEPMWinMac = ShieldIcons.shBtPmwm;
	public btEPMLinux = ShieldIcons.shBtPmul;

	public github = ShieldIcons.shGithub;
	public ping = ShieldIcons.shPingIdentity;
	public okta = ShieldIcons.shOkta;

	public iconHTML = `
<kendo-svg-icon
	[icon]="success"
	themeColor="success"
	class="sh-large-icon"
></kendo-svg-icon>`;

	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = iconStylesDefaultTheme;
	public stylesDefaultStatus = iconStylesDefaultThemeStatus;
	public stylesDark = iconStylesDarkTheme;
	public stylesDarkStatus = iconStylesDarkThemeStatus;
	public stylesTypography = iconStylesTypography;
	public stylesStructure = iconStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Display only',
					value: 'display-only',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Size and style',
					value: 'size-and-style',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
