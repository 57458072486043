// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const toastStylesDefaultThemeAll = [
	{
		name: 'Text',
		property: 'color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'd1d1d6',
		isColorTile: true,
	},
];
export const toastStylesDefaultThemeInfo = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const toastStylesDefaultThemeSuccess = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: '006E18',
		isColorTile: true,
	},
];

export const toastStylesDefaultThemeWarning = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Icon (outer circle)',
		property: 'color',
		value: 'FF9C00',
		isColorTile: true,
	},
	{
		name: 'Icon (inner exclamation mark)',
		property: 'color',
		value: '000000',
		isColorTile: true,
	},
];

export const toastStylesDefaultThemeError = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: 'C1001B',
		isColorTile: true,
	},
];

export const toastStylesDarkThemeAll = [
	{
		name: 'Text',
		property: 'color',
		value: 'fcfcfc',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '242424',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '3b3b3b',
		isColorTile: true,
	},
];
export const toastStylesDarkThemeInfo = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: '6699CC',
		isColorTile: true,
	},
];

export const toastStylesDarkThemeSuccess = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: '7FC5A2',
		isColorTile: true,
	},
];

export const toastStylesDarkThemeWarning = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: 'F6C664',
		isColorTile: true,
	},
];

export const toastStylesDarkThemeError = [
	{
		name: 'Progress Bar',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
	{
		name: 'Icon',
		property: 'background-color',
		value: 'FF8593',
		isColorTile: true,
	},
];

export const toastStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const toastStylesStructure = [
	{
		name: 'Button container',
		property: 'border-top-left-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-top-right-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Container',
		property: 'padding-top',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '288px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'max-width',
		value: '600px',
		isColorTile: false,
	},
	{
		name: '',
		property: '(application header height) + 16px',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'right',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-top',
		value: '4px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-left',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-left-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-bottom-right-radius',
		value: '6px',
		isColorTile: false,
	},
	{
		name: 'Icon',
		property: 'margin-right',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'width',
		value: '24px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
];

export const toastTextLinkStylesTypography = [
	{
		name: 'Text',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '20px',
		isColorTile: false,
	},
];

export const toastsTextLinkStylesStructure = [
	{
		name: 'Outline: focus',
		property: 'weight',
		value: '2px',
		isColorTile: false,
	},
];