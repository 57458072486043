<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Accessibility is the practice of removing barriers. Accessibility makes our products as usable to as
				many people as
				possible.
			</li>
		</ul>
	</section>
	<section class="page-section" id="why-accessibility-matters">
		<h2 id="sectionTitleWhyAccessibilityMatters" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('why-accessibility-matters')">
			Why accessibility matters<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				BeyondTrust’s mission is to build a world where identities and access are protected from
				cyberthreats — but who are the
				people that make up that world? Globally, an estimated 1.3 billion people experience disability.
				That’s 1 in 6 people.
				(<a href="https://www.who.int/en/news-room/fact-sheets/detail/disability-and-health"
					target="_blank">Health Organization, 2022</a>)
			</li>
			<li class="page-section-list-item">
				A disability is a condition that impairs someone’s ability to carry out their everyday tasks, like
				using software or the
				internet. Disabilities can be physical or cognitive, and they can be short-term, long-term, or even
				situational.
			</li>
			<li class="page-section-list-item">
				Accounting for disabilities doesn’t only benefit the minority of people. Improved usability comes
				with accessibility. As
				author and advocate Elise Roy says, “When we design for disability first, we often stumble upon
				solutions that are
				better than those when we design for the norm."
			</li>
			<li class="page-section-list-item">
				Everyone should have access to protection from cyberthreats. Making our products usable to more
				people isn’t only the
				“right” thing to do, but also is advantageous to our growth and protects us from legal action.
				Existing and potential
				customers often cite accessibility as a make-or-break selling point, and the number of companies who
				have been sued for
				violating accessibility laws increases each year.
			</li>
		</ul>
	</section>
	<section class="page-section" id="laws-and-guidelines">
		<h2 id="sectionTitleLawsGuidelines" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('laws-and-guidelines')">
			Accessibility laws and guidelines<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				BeyondTrust follows both <a href="https://www.section508.gov/" target="_blank">Section 508</a> and the
				<a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank">Web Content Accessibility
					Guidelines (WCAG)</a>.
			</li>
			<li class="page-section-list-item">
				What’s the difference?
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-do-list">
					<li class="page-section-list-item">
						Section 508 is a United States federal law.
					</li>
					<li class="page-section-list-item">
						WCAG is a set of globally recognized guidelines.
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				WCAG is broken into 3 levels: A, AA, AAA. Level A is the minimum requirement and AA is what most
				organizations strive for. <a
					href="https://www.w3.org/WAI/WCAG21/quickref/?currentsidebar=%23col_customize&levels=a%2Caaa"
					target="_blank">BeyondTrust follows WCAG AA</a>.
			</li>
		</ul>
	</section>
	<section class="page-section" id="criteria">
		<h2 id="sectionTitleCriteria" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('criteria')">
			Accessibility resources<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				The UX team has an accessibility <a
					href="https://beyondtrust.atlassian.net/wiki/spaces/UX/pages/490307993/Accessibility+Score+Card"
					target="_blank">scorecard</a> and <a
					href="https://beyondtrust.atlassian.net/wiki/spaces/UX/pages/537231791/Understanding+Section+508+VPAT+and+WCAG"
					target="_blank">criteria</a> available to measure our products against. These resources are
				informed by WCAG and Section 508.
			</li>
		</ul>
	</section>
	<section class="page-section" id="principles">
		<h2 id="sectionTitlePrinciples" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('principles')">
			Accessibility principles<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				WCAG’s 4 guiding principles of accessibility are POUR.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Perceivable</span>
				Information must be presented in ways users can see, hear, or feel.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Operable</span>
				Products should be functional no matter how they’re accessed.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Understandable</span>
				Content must be understood by all users.
			</li>
			<li class="page-section-list-item">
				<span class="page-section-list-item-sub-title">Robust</span>
				Products should remain usable as technology changes.
			</li>
		</ul>
	</section>
	<section class="page-section" id="designing">
		<h2 id="sectionTitleDesigning" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('designing')">
			Designing for accessibility<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Content and components cannot be invisible to users — regardless of their vision.
			</li>
			<li class="page-section-list-item">
				There must be a <a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
					target="_blank">contrast ratio of 4.5:1</a> between text and background color. Do not rely on color
				alone to
				communicate
				information to users. Certain colors cannot be combined to account for color blindness, like red and
				green.
			</li>
			<li class="page-section-list-item">
				Always label fields and controls and position the label nearby. Like color, don’t rely on icons alone to
				convey meaning.
			</li>
			<li class="page-section-list-item">
				Text must be large enough to be legible—a minimum of 14pt—and must be resizable.
			</li>
			<li class="page-section-list-item">
				Give users enough time to read content. Do not use content that could cause seizures or physical
				reactions.
			</li>
			<li class="page-section-list-item">
				Present content in a predictable way with a logical layout that makes information easy to locate.
				Most users read left to right, top to bottom, as do screen readers. Put the most critical content
				first and never bury need-to-know information in tooltips or help text.
			</li>
			<li class="page-section-list-item">
				Use the minimum target size (typically 24px) so that users can activate the right target.
			</li>
			<li class="page-section-list-item">
				Provide instructions and validation to help users complete tasks.
			</li>
		</ul>
	</section>
	<section class="page-section" id="developing">
		<h2 id="sectionTitleDeveloping" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('developing')">
			Developing for accessibility<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Writing semantically correct HTML is the easiest way to meet accessibility standards. Semantic elements
				determine the rules for how code gets interpreted. Semantic HTML ensures that structure, meaning, and
				state are
				conveyed to assistive technologies. This includes the proper order of headings.
			</li>
			<li class="page-section-list-item">
				While properly formatted HTML will cover most accessibility needs, use <a
					href="https://www.w3.org/WAI/standards-guidelines/aria/" target="_blank">Accessible Rich Internet
					Applications (ARIA)</a>
				attributes only when they benefit users. For example, the aria-hidden attribute will hide decorative,
				non-essential
				elements from assistive technologies.
			</li>
			<li class="page-section-list-item">
				By using Kendo, we already ensure our components are accessible — <a
					href="https://docs.telerik.com/kendo-ui/accessibility/section-508?_ga=2.150937398.1270722729.1697645028-41279024.1673288794&_gl=1*1pigoqw*_ga*NDEyNzkwMjQuMTY3MzI4ODc5NA..*_ga_9JSNBCSF54*MTY5NzY0NTAyNy4xMTMuMS4xNjk3NjQ1MTAyLjU0LjAuMA.."
					target="_blank">they are Section 508 compliant</a>.
			</li>
			<li class="page-section-list-item">
				Interactive elements should be functional, regardless of whether someone is using a keyboard, a mouse, a
				touchscreen, a
				joystick, or any other input mechanism. This includes the ability to tab between elements in a
				meaningful, logical
				order. Typically left to right, top to bottom. There should be no “keyboard traps” (places where you can
				tab onto an
				element but are unable to tab back out).
			</li>
			<li class="page-section-list-item">
				Videos must have captions, and images must have alt text that describes what’s happening. Alt text
				should be concise and
				contain the most important information. There is no need to include “image of” or “picture depicting.”
			</li>
			<li class="page-section-list-item">
				Maximize compatibility with current and future tools.
			</li>
			<li class="page-section-list-item">
				Each page needs a unique page title to distinguish it from other pages.
			</li>
		</ul>
	</section>
	<section class="page-section" id="writing">
		<h2 id="sectionTitleWriting" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('writing')">
			Writing for accessibility<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				Good writing helps users with low literacy, autism, cognitive and motor impairments, non-fluency, vision
				loss, stress, and more.
			</li>
			<li class="page-section-list-item">
				Content should be understood by all users. It should not be verbose or use technical jargon. Plain
				language is for everyone, even experts.
			</li>
			<li class="page-section-list-item">
				Our users don't have time to read lengthy, complex content. Be succinct. Present important information
				while using short sentences and basic syntax. Basic syntax means sentences should have only one main
				idea, have a subject and a verb, and any adjectives should appear before what they're describing.
			</li>
			<li class="page-section-list-item">
				Convey information without extra words and complex syntax and vocabulary.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									The critical detection is resolved.
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									The detection, which was critical in severity, has been remediated successfully.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Use the simplest, most straight forward diction.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Help
								</li>
								<li class="comparison-body-text-list-item">
									About
								</li>
								<li class="comparison-body-text-list-item">
									Word choice
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Assistance
								</li>
								<li class="comparison-body-text-list-item">
									Approximately
								</li>
								<li class="comparison-body-text-list-item">
									Diction
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Use the active voice and first person, like you're speaking directly to users.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									You saved the settings.
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									The settings were saved.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Assuming users will understand technical terms is a common misconception that is frequently proven wrong
				by user feedback and research. Take the time to define terminology, explain concepts, and spell out
				acronyms.
			</li>
			<li class="page-section-list-item">
				Keep labels consistent across screens and products.
			</li>
			<li class="page-section-list-item">
				Choose inclusive language that doesn't exclude users with assistive technologies.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Select
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									Click
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Avoid idioms and metaphors. Don't use abbreviations or shortforms, which take more cognitive energy to
				decipher. This includes Latin.
			</li>
			<li class="page-section-list-item">
				<ul class="page-section-comparison-list">
					<li class="page-section-comparison-list-item comparison-do">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="doThisIcon"></kendo-svg-icon>
							Try this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									For example.
								</li>
							</ul>
						</div>
					</li>
					<li class="page-section-comparison-list-item comparison-do-not">
						<div class="page-section-comparison-header">
							<kendo-svg-icon [icon]="notThisIcon"></kendo-svg-icon>
							Not this
						</div>
						<div class="page-section-comparison-body">
							<ul class="comparison-body-text-list">
								<li class="comparison-body-text-list-item">
									E.g., i.e.
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</li>
			<li class="page-section-list-item">
				Help users correct mistakes by writing clear, helpful error messages. Provide as much information as
				possible without compromising security. Don't blame users or apologize too much, both are frustrating.
				Limit humor that could delay someone trying to do their job. When writing inline errors, be specific
				about what field the user needs to correct.
			</li>
			<li class="page-section-list-item">
				Links should clearly and accurately describe where they will take users. Don't use “Learn More” — it
				confuses screen readers, especially when there are multiple links with this label. Link text does not
				need to contain the action, like “Open”, as screen readers already know it's a link.
			</li>
			<li class="page-section-list-item">
				Reading level detectors are a helpful starting point, but don't rely on them alone. Find the words users
				use through research and test content for comprehension.
			</li>
		</ul>
	</section>
	<section class="page-section" id="resources">
		<h2 id="sectionTitleResources" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('resources')">
			Resources<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>

		<ul class="page-section-list">
			<li class="page-section-list-item resource-list-item">
				<a href="https://equidox.co/blog/the-four-pillars-of-web-content-accessibility-guidelines-wcag/"
					target="_blank">
					The 4 Pillars of Web Content Accessibility Guidelines (WCAG)
				</a>
			</li>
			<li class="page-section-list-item resource-list-item">
				<a href="https://www.nngroup.com/articles/accessibility-inclusivity-study-guide/" target="_blank">
					Accessibility and Inclusivity: Study Guide
				</a>
			</li>
			<li class="page-section-list-item resource-list-item">
				<a href="https://www.w3schools.com/html/html5_semantic_elements.asp" target="_blank">
					HTML Semantic Elements
				</a>
			</li>
			<li class="page-section-list-item resource-list-item">
				<a href="https://readabilityguidelines.co.uk/" target="_blank">
					Readability Guidelines
				</a>
			</li>
			<li class="page-section-list-item resource-list-item">
				<a href="https://romeo.elsevier.com/accessibility_checklist/" target="_blank">
					Accessibility Checklist
				</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>
	</section>

	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Accessibility Guidelines'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>