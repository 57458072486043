import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shCheck, shXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-colors',
	templateUrl: './colors.component.html',
	styleUrls: ['./colors.component.less'],
})
export class ColorsComponent {
	public currVisible = 'overview';

	public doThisIcon = shCheck;
	public notThisIcon = shXmark;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
			],
		},
		{
			name: 'Themes',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Light theme',
					value: 'light',
				},
				{
					name: 'Dark theme',
					value: 'dark',
				},
				{
					name: 'Accent',
					value: 'accents',
				},
				{
					name: 'Data visualization',
					value: 'data',
				},
				{
					name: 'Severity and status',
					value: 'severity',
				},
			],
		},
		{
			name: 'Accessibility',
			value: 'accessibility',
			empty: false,
			children: [
				{
					name: 'WCAG 2.0 Level AA Contrast Requirements',
					value: 'wcag',
				},
			],
		},
		// {
		// 	name: 'Resources',
		// 	value: 'resources',
		// 	empty: false,
		// 	children: [],
		// },
		// {
		// 	name: 'Related',
		// 	value: 'related',
		// 	empty: false,
		// 	children: [],
		// },
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public redirectTo(uri: any) {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate([uri]));
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}
}
