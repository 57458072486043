<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Pagination'" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Default</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<div class="page-live-example" [class.page-live-show]="currLive === 'default'">
					<kendo-grid class="sh-data-table" [kendoGridBinding]="pagedData" [sortable]="false" [filterable]="false" [pageable]="false" [style.height]="gridHeight" [scrollable]="'none'">
						<kendo-grid-column
							field="EventTime"
							title="Event Time"
						><ng-template
								kendoGridCellTemplate
								let-dataItem
							>
								{{dataItem.EventTime | date:
								'MM/dd/yyyy&nbsp;&nbsp;hh:mm:ss a'}}
							</ng-template></kendo-grid-column>
						<kendo-grid-column field="EventType" title="Event Type"></kendo-grid-column>
						<kendo-grid-column field="EventAction" title="Event Action"></kendo-grid-column>
						<kendo-grid-column field="ApplicationName" title="Application Name"></kendo-grid-column>
						<kendo-grid-column field="ApplicationType" title="Application Type"></kendo-grid-column>
					</kendo-grid>
					<kendo-datapager
						aria-controls="data-id"
						size="medium"
						type="input"
						[total]="100"
						[pageSize]="pageSize"
						[skip]="skip"
						(pageChange)="onPageChange($event)"
						[pageSizeValues]="[10, 25, 50, 100, 200]">
					</kendo-datapager>
				</div>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-started">
			<h3 id="subSectionTitleDevStarted" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-started')">
				Getting started<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			Added to your project's app.module.ts file:
			<code style="margin-top: 10px">import &#123; PagerModule &#125; from
				'&#64;progress/kendo-angular-pager';
			</code>
		</section>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
					<path
						d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
				</svg>
			</h3>
			<pre prism class="dark">{{ paginationHTML }}</pre>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop-down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of dropDownStylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of iconButtonStylesDefault" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop-down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of dropDownStylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of iconButtonStylesDark" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesTypography" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop-down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of dropDownStylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Text field</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of textFieldStylesStructure" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Drop-down</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of dropDownStylesStructure" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Icon button</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of iconButtonStylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>

	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-disable">
			<h3 id="subSectionTitleBehaviorsDisable" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-disable')">
				Disable <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pagination controls are disabled while the corresponding component loads.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-controls">
			<h3 id="subSectionTitleBehaviorsControls" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-controls')">
				Pagination controls <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pagination controls are used to navigate pages of data. These controls appear
					to the left-most side of the component.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\pagination_control_section.png" alt="" />
					<span class="redlines-caption">Example of pagination controls.</span>
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">First page</span>
					Navigates the user to the first page of the dataset. This control is disabled
					if a user is already on the first page.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Previous page</span>
					Navigates the user to the previous page in the dataset. Previous always
					meaning 1 lower in the page count, not the last visited page. For example,
					a user on page 5 would be taken to page 4. This control is disabled if the
					user is on the first page.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Text field</span>
					Pagination has a text field where users can input a page number. When the
					user presses the enter key or removes focus from the field, it takes them
					to the page they input.
				</li>
				<li class="page-section-list-item">
					The label around the text field is "Page" and "of Y”. The current page is
					the text field value and the Y is the total number of pages. If there’s no
					data, the text in the pagination control should be “Page 1 of 1”.
				</li>
				<li class="page-section-list-item">
					Only numbers can be entered into the text field. Entering a number above
					the maximum page count automatically lowers it to the number of the last
					page. For example, entering 5 when there are only 4 pages changes the value
					to 4 — the Kendo default. Entering 0 changes the value to 1.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Next page</span>
					Navigates user to the following page in the dataset. For example, if a
					user is on page 5 they would be taken to page 6. This control is disabled
					if the user is already on the last page.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Last page</span>
					Navigates user to the last page in the dataset. This control is disabled
					if the user is already on the last page.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-items-per-page">
			<h3 id="subSectionTitleBehaviorsItemsPerPage" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-items-per-page')">
				Items per page drop-down <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\pagination_items_per_page.png" alt="" />
					<span class="redlines-caption">Example of spacing around dropdown and items per page text.</span>
				</li>
				<li class="page-section-list-item">
					A drop-down in the middle of the component lets the user select how many
					items to display per page. The options are 10, 25, 50, 100, and 200. 10, or
					the lowest amount, should be the default. This selection should persist
					for the user if the application supports it. The drop-down does not support
					an empty value.
				</li>
				<li class="page-section-list-item">
					The drop-down label should be "X items per page”. The value of X is a
					drop-down with the current selection, followed by “items per page”.
				</li>
				<li class="page-section-list-item">
					The width of the drop-down is smaller than a typical drop-down. They should
					be just wide enough to contain the largest value without cutting off any text.
					Kendo will truncate any text that doesn’t have room.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-items-text">
			<h3 id="subSectionTitleBehaviorsItemsText" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-items-text')">
				Showing items text <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Text reading "X-Y of Z items" appears on the right-most side of pagination. X and
					Y is the range of data items that are shown on the current page, and Z is the total
					number of records. As a user pages through the data, the current range automatically
					changes to show where they are in the dataset. For example,  “41-65 of 500 items”.
				</li>
				<li class="page-section-list-item">
					<img class="guidance-image" src="\assets\images\pagination_last_section.png" alt="" />
					<span class="redlines-caption">Example of showing items text.</span>
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-general">
			<h3 id="subSectionTitleUsageGeneral" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-general')">
				General <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pagination is normally used for data grids and lists. If there is no data
					loaded in the component, pagination is shown but disabled.
				</li>
				<li class="page-section-list-item">
					You can choose to not use pagination for data tables and lists with minimal
					data, but only if you are certain that the dataset will not get any bigger.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsageContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Labels</span>
					There are 3 labels that make up pagination. A text field label on the left, a drop-down label in the middle, and another label on the right.
				</li>
				<li class="page-section-list-item">
					The text field on the left has the label "Page X of Y”.
				</li>
				<li class="page-section-list-item">
					The drop-down label in the middle is "X items per page”. The value of X is the drop-down, followed by “items per page”.
				</li>
				<li class="page-section-list-item">
					The third label on the right is "X-Y of Z items". If there is no data to be paged through, the label should be “Page 1 of 1”.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-position">
			<h3 id="subSectionTitleUsagePosition" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-position')">
				Position <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Pagination is placed at the bottom of the component it controls. It extends the full width of that component.
				</li>
			</ul>
		</section>
	</section>

	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/button">Button component</a>
			</li>
			<li class="page-related-list-item">
                <a href="guidelines/colors">Colors guidelines</a>
            </li>
			<li class="page-related-list-item">
				<a href="components/drop-down">Drop down component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/text-field">Text field component</a>
			</li>
			<li class="page-related-list-item">
				<a href="guidelines/typography">Typography guidelines</a>
			</li>
		</ul>

		<span class="page-related-list-title">Components and patterns that use pagination:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/data-grid">Data grid component</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
				<path
					d="M172.5 131.1C228.1 75.51 320.5 75.51 376.1 131.1C426.1 181.1 433.5 260.8 392.4 318.3L391.3 319.9C381 334.2 361 337.6 346.7 327.3C332.3 317 328.9 297 339.2 282.7L340.3 281.1C363.2 249 359.6 205.1 331.7 177.2C300.3 145.8 249.2 145.8 217.7 177.2L105.5 289.5C73.99 320.1 73.99 372 105.5 403.5C133.3 431.4 177.3 435 209.3 412.1L210.9 410.1C225.3 400.7 245.3 404 255.5 418.4C265.8 432.8 262.5 452.8 248.1 463.1L246.5 464.2C188.1 505.3 110.2 498.7 60.21 448.8C3.741 392.3 3.741 300.7 60.21 244.3L172.5 131.1zM467.5 380C411 436.5 319.5 436.5 263 380C213 330 206.5 251.2 247.6 193.7L248.7 192.1C258.1 177.8 278.1 174.4 293.3 184.7C307.7 194.1 311.1 214.1 300.8 229.3L299.7 230.9C276.8 262.1 280.4 306.9 308.3 334.8C339.7 366.2 390.8 366.2 422.3 334.8L534.5 222.5C566 191 566 139.1 534.5 108.5C506.7 80.63 462.7 76.99 430.7 99.9L429.1 101C414.7 111.3 394.7 107.1 384.5 93.58C374.2 79.2 377.5 59.21 391.9 48.94L393.5 47.82C451 6.731 529.8 13.25 579.8 63.24C636.3 119.7 636.3 211.3 579.8 267.7L467.5 380z" />
			</svg>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Pagination Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
